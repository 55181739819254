import React, { useEffect } from "react";
import { api_bd_nr } from "../../services/Api";

import customerAlertsState, { alertsCounterState, alertsCustomerListState, alertsViewDateState, alertsViewOptionState }  from "../../atoms/customerAlerts";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

export default function LoadCustomerAlerts({ showLabel = false, viewOption="", setViewoption=()=>{} }) {
    // const [customerAlertData, setCustomerAlertData] = useRecoilState(customerAlertsState)
    const setCustomerAlertData = useSetRecoilState(customerAlertsState)
    // const [customerAlertsList, setCustomerAlertsList] = useRecoilState(customerAlertsListState)

    const counter = useRecoilValue(alertsCounterState)
    const optionView = useRecoilValue(alertsViewOptionState)
    const viewDate = useRecoilValue(alertsViewDateState)
    const customerList = useRecoilValue(alertsCustomerListState)

    const loadCustomers = () => {
        api_bd_nr.get('/outage/customeralerts?opt=customerlist').then(({ data }) => {
            // console.log('Loader Customer List', data)
            setCustomerAlertData(current => {
                return ({
                    ...current,
                    customerList: data || []
                })
            })
            // setCustomerAlertsList(data)
        }).catch(e => {
            console.log('Loader Customer List get error:', e)
        })
    }

    const loadEvents = (optionView, viewDate = false) => {
        api_bd_nr.get(`/outage/customeralerts?opt=${optionView}${viewDate ? '&d=' + viewDate : ''}`).then(({ data }) => {
            // console.log('Loader Customers Events', data)
            // alert(data.length)
            setCustomerAlertData(current => {
                // console.log('loadcustomer data', data)
                // console.log('loadcustomer current', current)
                return ({
                    ...current,
                    counter: data?.length || 0,
                    events: data || []
                })
            })
        }).catch(e => {
            console.log('Loader CustomerAlerts get error:', e)
        })
    }

    useEffect(() => {
        //load list of clients
        loadCustomers()
    }, [])

    useEffect(() => {
        let interval = null
        // if (customerAlertData?.customerList?.length > 0) {
            if (optionView === 'ack') {
                loadEvents(optionView, viewDate)
                // clearInterval(interval)
                interval = setInterval(() => {
                    loadEvents(optionView, viewDate)
                }, 120000)
            } else {
                loadEvents(optionView)
                // clearInterval(interval)
                interval = setInterval(() => {
                    loadEvents(optionView)
                }, 50000)//120000)
            }
        // }
        return () => {
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        if (optionView === 'ack') {
            loadEvents(optionView, viewDate)
        } else {
            loadEvents(optionView)
        }

    }, [customerList, optionView, viewDate])


    // }, [customerList, optionView, viewDate])

    return (
        showLabel ? (
            <span
                style={
                    viewOption === 'customerAlerts'
                        ?
                        { fontSize: '1.4em', fontWeight: 'bold', textDecoration: 'underline', textDecorationThickness: '0.2em', cursor: 'pointer' }
                        :
                        { fontSize: '1.4em', fontWeight: 'bold', cursor: 'pointer' }
                }
                onClick={() => { setViewoption('customerAlerts') }}
            >
                ALERTAS CLIENTES
                {counter > 0 &&
                    // <span className='mt-[-5px] ml-[5px] text-center text-sm absolute p-2 w-[26px] h-[26px] bg-intec text-white rounded-full'>{String(customerAlertsCounter?.counter||0).padStart(2, '0')}</span>
                    <>&nbsp;({String(counter || 0).padStart(2, '0')})</>
                }
            </span>
        ) : (<></>)
    )
}