import axios from "axios";

const { api_backend_address, api_backend_address_nr, api_glpi_address } = require('../config.json')

const TOKEN_KEY = "@sgiuser";

const UD_KEY = "@sgi-Data";

const APP_TOKEN = 'ra0PoNwU6Ix5DA1Fz8xsDhAhKcgiv6D65eFCzeMh'

const https = require('https');

export const getLoginToken = async () => {
	const data = await localStorage.getItem(TOKEN_KEY)
	const JSONData = JSON.parse(data)
	return(JSONData?.userState.glpi_data?.access_token)
};

export const isAuthenticated = async () => localStorage.getItem(TOKEN_KEY);

export const setLoginToken = async (token) => { localStorage.setItem(TOKEN_KEY, token) };

export const setUserData = async (_data) => {
	let data_user = { ..._data }
	const user_sgi = await api_bd.get(`/users/${data_user.session.glpiname}`)
	if (user_sgi.data.length > 0) {
		data_user.session['user_sgi'] = user_sgi.data[0]
	}
	localStorage.setItem(UD_KEY, JSON.stringify(data_user))
};

export const updateSgiUserData = async (_data) => {
	let data_user = await getUserData()
	let newdata = {}
	newdata['session'] = {...data_user}
	newdata['session']['user_sgi'] = {..._data}
	localStorage.setItem(UD_KEY, JSON.stringify(newdata))
};

export const getUserData = async () => {
	const data = localStorage.getItem(UD_KEY)
	return JSON.parse(data).session
};

export const login_gpli = async (username, password) => {
	const usernamePasswordBuffer = Buffer.from(username + ':' + password);
	const base64data = usernamePasswordBuffer.toString('base64');

	return api_glpi.get('/initSession', {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Basic ${base64data}`
		}
	})
		.then(async (resp) => {
			await setLoginToken(resp.data.session_token)
			await get_user_data_glpi()
			return (resp.data.session_token)
		})
		.catch(e => {
			// console.log(e)
		})
};

export const get_user_data_glpi = async () => {
	return api_glpi.get('/getFullSession')
		.then(async (resp) => {
			await setUserData(resp.data)
			return (resp.data)
		})
		.catch(e => {
			// console.log(e)
		})
}

export const logout = async () => {
	localStorage.removeItem(TOKEN_KEY)
	localStorage.removeItem(UD_KEY)
	localStorage.removeItem('filtro_situacao')
	localStorage.removeItem('filtro_tipo_solicitacao')
	localStorage.removeItem('filtro_pesquisa')
	localStorage.removeItem('filtro_cliente')
};

export const api_bd = axios.create({ baseURL: api_backend_address });
export const api_bd_nr = axios.create({ baseURL: api_backend_address_nr });

api_bd.interceptors.request.use(async (request) => {
	// console.log('sgiuser:::', await getUserData())
	const token = await getLoginToken();
	if (token) {
		request.headers['Session-Token'] = `${token}`
	}
	request.headers['App-Token'] = `${APP_TOKEN}`
	return request;
})

api_bd_nr.interceptors.request.use(async (request) => {
	const token = await getLoginToken();
	if (token) {
		request.headers['Session-Token'] = `${token}`
	}
	request.headers['App-Token'] = `${APP_TOKEN}`
	return request;
})

export const api_glpi = axios.create({ baseURL: api_glpi_address });
api_glpi.interceptors.response.use(response => {
	if (response.status == 200) {
		if (response.config.url == '/initSession') {
			let token = response.data.session_token
			setLoginToken(token)
		}
	}
	return response
})

api_glpi.interceptors.request.use(async config => {
	const token = await getLoginToken();
	if (token) {
		config.headers['Session-Token'] = `${token}`
	}
	config.headers['App-Token'] = `${APP_TOKEN}`
	return config;
});


export default api_glpi;


