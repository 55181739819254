import React, { useEffect, useState } from "react";
import { api_bd_nr } from "../../services/Api";
// import Modal from "../Modal3";

// import { profileData } from "../../atoms/user.atom";
// import { useRecoilValue } from "recoil";

import CardCustomer from "./CardCustomer";
import customerAlertsState from "../../atoms/customerAlerts";
import { useRecoilState, useSetRecoilState } from "recoil";

// const CardCustomer = (props) => {
//     const customer = props.customer
//     const events = props?.listEvents?.length > 0 ? props?.listEvents?.filter(el => el?.customer?.key === customer?.zabbixkey) : []
//     const [showModal, setShowModal] = useState(false)
//     const loggedUser = useRecoilValue(profileData)

//     // console.log(loggedUser)

//     return (
//         <>
//             <Modal
//                 show={showModal}
//                 setShow={setShowModal}
//                 onClose={() => {
//                     if (props?.setOptionView) {
//                         props.setOptionView('unack')
//                     }
//                 }}
//             >
//                 <div
//                     className="w-[90dvw] h-[90dvh] max-w-[90dvw] max-h-[90dvh] bg-white rounded"
//                 >
//                     <p className="p-5 text-xl font-bold">{customer.displayName}</p>
//                     <p className="pl-5 font-bold">
//                         Visualizar: <select
//                             value={props.optionView || 'unack'}
//                             onChange={(e) => {
//                                 if (props?.setOptionView) {
//                                     props.setOptionView(e.target.value)
//                                 }
//                             }}
//                         >
//                             <option value="unack">Eventos não reconhecidos</option>
//                             <option value="ack">Eventos reconhecidos</option>
//                         </select>
//                     </p>
//                     {props.optionView === 'ack' &&
//                         <p className="pl-5 font-bold">
//                             Data:  <input
//                                 type="date"
//                                 value={props.viewDate}
//                                 onChange={(e) => {
//                                     if (props.setViewDate) {
//                                         props.setViewDate(e.target.value)
//                                     }
//                                 }}
//                             />
//                         </p>
//                     }
//                     <div className="flex flex-row flex-wrap gap-4 p-4 mt-4 w-[100%] max-w-[100%] max-h-[calc(90dvh_-_80px)] overflow-y-auto">
//                         {events.map(el => (
//                             <div
//                                 className="p-3 rounded min-w-[250px] w-[250px] h-fit"
//                                 style={{
//                                     border: "solid 1px rgb(224, 224, 224)",
//                                     boxShadow: "#0006 2px 3px 10px",
//                                 }}
//                             >
//                                 {/* <p><span className="m-0 font-bold">Cliente </span>{el.customer}</p> */}
//                                 <p><span className="m-0 font-bold">Severity: </span>{el.severity}</p>
//                                 <p><span className="m-0 font-bold">Tipo: </span>{el.type}</p>
//                                 <p><span className="m-0 font-bold">Data: </span>{el.date ? new Date(el.date).toLocaleDateString() + ' às ' + new Date(el.date).toLocaleTimeString() : ''}</p>
//                                 {el?.acknowledged?.status ? (
//                                     <div
//                                         className="w-full mt-3 rounded bg-green-500 text-white p-2"
//                                     >
//                                         ACK: {el.acknowledged.user.nome}
//                                     </div>
//                                 ) : (
//                                     <input
//                                         className="w-full mt-3 rounded bg-intec text-white p-2 cursor-pointer"
//                                         type="button"
//                                         value="Reconhecer evento"
//                                         onClick={() => {
//                                             api_bd_nr.put(`/outage/customeralerts/${el._id}?opt=ack`, { ackUser: loggedUser }).then(({ data }) => {
//                                                 console.log('URL: ', `/outage/customeralerts/${el._id}`)
//                                                 console.log(data)
//                                                 if (props?.reloadEvents) {
//                                                     props.reloadEvents()
//                                                 }
//                                             }).catch(e => {
//                                                 console.error('PUT EVENT Error: ', e)
//                                             })
//                                         }}
//                                     />
//                                 )}

//                             </div>
//                         ))}

//                         {events.length === 0 && props?.optionView === 'unack' &&
//                             <div
//                                 className="w-full mt-3 rounded bg-green-500 text-white p-2"
//                             >
//                                 TUDO OK! nos últimos 5 minutos
//                             </div>
//                         }

//                         {events.length === 0 && props?.optionView === 'ack' &&
//                             <div
//                                 className="w-full mt-3 rounded bg-intec text-white p-2"
//                             >
//                                 Nenhum evento reconhecido para a pesquisa atual
//                             </div>
//                         }
//                     </div>
//                 </div>
//             </Modal>
//             <div className={`flex flex-row items-start m-4 ${events?.length > 0 ? 'bg-red-500' : 'bg-intec' } text-white rounded py-3 px-4 min-w-[400px] space-x-4 items-center`}>
//                 <div>
//                     <img
//                         className="w-[100px] rounded-full"
//                         src={`https://apisgi.intecsolutions.com.br/api/customers/getimg/${customer.username}`}
//                     />
//                 </div>
//                 <div className="w-full flex flex-col relative">
//                     <div className="flex flex-row">
//                         <p className="text-[1.5rem]">
//                             <span className="text-[1.5rem] m-0 font-bold">Cliente: </span>
//                             {customer.displayName}
//                         </p>
//                         {events.length > 0 &&
//                             <div
//                                 className="flex justify-center items-center w-fit h-fit p-2 bg-intec"
//                                 style={{
//                                     marginLeft: 10,
//                                     marginTop: -8,
//                                     minWidth: 30,
//                                     borderRadius: '50%',
//                                     aspectRatio: '1/1'
//                                 }}
//                             ><span className="m-0">{events.length}</span></div>
//                         }
//                     </div>
//                     <input
//                         className="w-full mt-3 rounded bg-intec-700 text-white p-2 cursor-pointer"
//                         type="button"
//                         value="Listar eventos"
//                         onClick={() => {
//                             console.log(!showModal)
//                             setShowModal(!showModal)
//                         }}
//                     />
//                 </div>
//             </div>
//         </>
//     )
// }



export default function CustomerAlerts(props) {
    // const [customerList, setCustomerList] = useState([])
    // const [listAlerts, setListAlerts] = useState([])
    // const [optionView, setOptionView] = useState('unack')
    // const [viewDate, setViewDate] = useState(new Date().toLocaleDateString('en-ca'))
    const [customerAlertData, setCustomerAlertData] = useRecoilState(customerAlertsState)

    // const loadCustomers = (_set = false) => {
    //     api_bd_nr.get('/outage/customeralerts?opt=customerlist').then(({ data }) => {
    //         console.log('Customer List', data)
    //         if (_set) {
    //             _set(data)
    //         }
    //     }).catch(e => {
    //         console.log('Customer List get error:', e)
    //     })
    // }

    // const loadEvents = (_set = false, optionView, viewDate = false) => {
    //     api_bd_nr.get(`/outage/customeralerts?opt=${optionView}${viewDate ? '&d=' + viewDate : ''}`).then(({ data }) => {
    //         console.log('Customers Events', data)
    //         // alert(data.length)
    //         if (_set) {
    //             _set(data)
    //         }
    //         setCustomerAlertData(current => {
    //             return ({ ...current, counter: data?.length || 0, alerts: data || [] })
    //         })
    //     }).catch(e => {
    //         console.log('CustomerAlerts get error:', e)
    //     })
    // }

    const loadCustomers = () => {
        api_bd_nr.get('/outage/customeralerts?opt=customerlist').then(({ data }) => {
            // console.log('Loader Customer List', data)
            setCustomerAlertData(current => {
                return ({
                    ...current,
                    customerList: data || []
                })
            })
        }).catch(e => {
            console.log('Loader Customer List get error:', e)
        })
    }

    const loadEvents = (optionView, viewDate = false) => {
        api_bd_nr.get(`/outage/customeralerts?opt=${optionView}${viewDate ? '&d=' + viewDate : ''}`).then(({ data }) => {
            // console.log('Loader Customers Events', data)
            // alert(data.length)
            setCustomerAlertData(current => {
                return ({
                    ...current,
                    counter: data?.length || 0,
                    events: data || []
                })
            })
        }).catch(e => {
            console.log('Loader CustomerAlerts get error:', e)
        })
    }

    // useEffect(() => {
    //     loadCustomers()
    // }, [])

    // useEffect(() => {
    //     let interval = null
    //     if (customerList.length > 0) {
    //         if (optionView === 'ack') {
    //             clearInterval(interval)
    //             interval = setInterval(() => {
    //                 loadEvents(optionView, viewDate)
    //             }, 60000)
    //         } else {
    //             clearInterval(interval)
    //             interval = setInterval(() => {
    //                 loadEvents(optionView)
    //             }, 60000)
    //         }
    //     }
    //     return () => {
    //         clearInterval(interval)
    //     }
    // }, [customerList, optionView, viewDate])


    // URL IMAGES
    // https://apisgi.intecsolutions.com.br/api/customers/getimg/:customername


    return (
        // <div
        //     // className='container'
        //     style={{
        //         backgroundColor: 'transparent',
        //         width: '100%',
        //         overflowY: 'auto',
        //         overflowX: 'hidden',
        //     }}
        // >
        <div
            className={`flex flex-row ${props.standalone ? 'max-h-[calc(100dvh_-_90px)]' : 'max-h-[calc(100dvh_-_195px)]' } w-[calc(100%_-_15px)] h-[100dvh] overflow-y-auto`}
            style={{
                margin: 20,
                // width: "100%",
                // minWidth: "100%",
                // display: "flex",
                // flexDirection: 'row',
                alignContent: 'flex-start',
                flexWrap: 'wrap',
                padding: 10,
                boxShadow: "#0006 2px 3px 10px",
                borderRadius: 5
            }}
        >
            {customerAlertData.customerList.map(el => (
                <CardCustomer
                    customer={el}
                    listEvents={customerAlertData.events}
                    reloadEvents={() => {
                        if (customerAlertData.optionView === 'ack') {
                            loadEvents(customerAlertData.optionView, customerAlertData.viewDate)
                        } else {
                            loadEvents(customerAlertData.optionView)
                        }
                    }}
                    setCustomerAlertData={setCustomerAlertData}
                    // setOptionView={setOptionView}
                    optionView={customerAlertData.optionView}
                    viewDate={customerAlertData.viewDate}
                    // setViewDate={setViewDate}
                />
            ))}
        </div>
        // </div>
    )
}