import React, { useState, useEffect, useRef } from 'react';

import { Card } from '@material-ui/core';
// import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Badge from '@material-ui/core/Badge';

import { Link } from 'react-router-dom';
// import * as IoIcons from 'react-icons/io';
import Styles, { Fab, MiniFab, LineMenuHome, primary_color } from '../Styles'

import {
    api_bd
} from '../../services/Api'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import customerData from '../../atoms/customers.atom'
import clientDetails from '../../atoms/client_details.atom'
// import userTickets from '../../atoms/tickets.atom'
import navigationState from '../../atoms/navigate_state.atom'
import { modalDataState, showMenuState } from '../../atoms/appState'

import '../Navbar/Navbar.css';

import LogoColor from '../../assets/logo_big_intec.png'

// import AvisoHome from '../../assets/aviso_home.jpeg'

import Modal from '../Modal'

import FormDataUser from '../Forms/FormDataUser'

import DeskAutomation from '../DeskAutomation';

// import ModalTikets from '../ModalTickets'

import { CalendarCheck2, Building } from 'lucide-react'

const { backend_files_address } = require('../../config.json')

function LeftSideBar(props) {
    const styles = Styles();

    const [navigation, set_navigation] = useRecoilState(navigationState)

    const sgi_user = useRecoilValue(userState)

    const profile_data = useRecoilValue(profileData)

    const set_client_details = useSetRecoilState(clientDetails)

    const [data_home, set_data_home] = useRecoilState(customerData)

    const [glpi_level, set_glpi_level] = useState('')

    const [show_modal, set_show_modal] = useState(false)

    const [show_loading, set_show_loading] = useState(true)

    const [device_height, set_device_height] = useState(null)

    const [device_width, set_device_width] = useState(null)

    const [show_install_button, set_show_install_button] = useState(false)

    const [deferredPrompt, set_deferredPrompt] = useState(null)

    const menu_lateral_ref = useRef(null);

    // const [showMenu, setShowMenu] = useRecoilState(showMenuState)
    const showMenu = useRecoilValue(showMenuState)

    const setViteModal = useSetRecoilState(modalDataState)

    const set_show_menu_left = () => {
        // menu_lateral_ref.classList.toggle("mystyle");
        menu_lateral_ref.current.classList.toggle('menu_close')
    }

    const actualDate = new Date()

    useEffect(() => {
        set_client_details({})
        const dw = document.documentElement.clientWidth
        const dh = document.documentElement.clientHeight

        // console.log(dw) 

        set_device_width(dw)
        set_device_height(dh)

        // let deferredPrompt; // Allows to show the install prompt
        // let setupButton;
        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            set_deferredPrompt(e);
            //console.log("beforeinstallprompt fired");

            // if (setupButton == undefined) {
            //     setupButton = document.getElementById("setup_button");
            // }

            // Show the setup button
            // setupButton.style.display = "inline";
            // setupButton.disabled = false;
            set_show_install_button(true)
        });

        return () => {
            window.removeEventListener('beforeinstallprompt', (e) => {
                //console.log("beforeinstallprompt listner cleaned up");
            });
        };

    }, [])


    useEffect(() => {
        // console.log('Page HOME', profile_data, sgi_user)
        // console.log('ProfileData', profile_data)
        // console.log('UserData', sgi_user)
        set_glpi_level(profile_data.nivel)
        // console.log(sgi_user)
    }, [sgi_user])


    useEffect(() => {
        (async () => {
            set_show_loading(true)
            if (glpi_level.match(/Cliente/i)) {
                const dados = await api_bd.get(`/customers/glpiuser/${profile_data.username}`)
                set_show_loading(false)
                if (dados.data.length > 0) {
                    set_data_home(dados.data)
                }
            } else if (glpi_level.match(/Técnico/i) || glpi_level === 'Super') {
                const dados = await api_bd.get(`/customers`)
                const ordered_data = dados.data ? await dados.data.sort((a, b) => a.nome.localeCompare(b.nome)) : []
                // set_data_home(dados.data)
                set_show_loading(false)
                set_data_home(ordered_data)
            }


        })()
    }, [glpi_level])



    return (
        <div
            style={{
                // display: 'flex',
                // flexDirection: 'row',
                // flexWrap: 'wrap',
                // //// marginLeft: position_left_side_bar,
                // top: 0,
                minWidth: 220,
                maxWidth: 220,
                marginLeft: showMenu ? 0 : -220,
                transition: 'margin-left 1s',
                // justifyContent: 'flex-start',
                // alignItems: 'flex-start',
                overflowY: "auto",
                overflowX: "hidden",
                backgroundColor: "#3c5ca6",
            }}
            //ref={menu_lateral_ref}
            className="height_less_header"
        // "height_less_header menu_open"
        >
            {/* {device_width <= 1220 ? show_menu_left ? 'menu_open' : 'menu_close' : ''} */}
            
            {/* <div
                style={{ 
                    top: -38, 
                    left: 10, 
                    position: 'absolute',
                    zIndex: 1
                }}
                // className=" ticketsTableMin"
                onClick={() => {
                    // set_show_menu_left(!show_menu_left)
                    // set_show_menu_left()
                    setShowMenu(!showMenu)
                }}
            >
                <span style={{ cursor: 'pointer', color: '#fff', fontSize: '2.3em' }} className="material-icons">menu</span>
            </div> */}
            
            <div
                className="height_less_header flex flexRow"
                style={{
                    minWidth: 220,
                    width: 220,
                    maxWidth: 220,
                    alignItems: "flex-start"
                }}>
                {show_modal &&
                    <Modal callbackfn={set_show_modal}>
                        <FormDataUser
                            // width={device_width < device_height ? "80%" : "50%"}
                            edit={profile_data}
                            avatar={profile_data.nivel.match(/Cliente/i) ? `${backend_files_address}/${data_home[0].logomarca.filename}` : false}
                            callback={(_newData) => {
                                // console.log(_newData)
                                // set_user_sgi(_newData)
                                set_show_modal(false)
                            }} />
                    </Modal>
                }

                <>
                    {(data_home.length > 0 || glpi_level.match(/Outage-user|Outage-level-1/i)) &&
                        <div
                            style={{
                                backgroundColor: "#3c5ca6",
                                color: "white",
                                position: 'relative',
                            }}
                            className="height_less_header"
                        >

                            {actualDate.getMonth() === 11 && // Gorro de natal no Perfil
                                <img
                                    style={{
                                        width: 120,
                                        float: 'left',
                                        position: 'absolute',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 89,
                                        marginTop: -3,
                                        transform: 'rotate(19deg)',
                                        minWidth: 30,
                                        minHeight: 30,
                                        zIndex: 1
                                    }}
                                    src="https://sgi.intecsolutions.com.br/gorro-natal-r.png"
                                />
                            }

                            <div style={{ position: 'relative' }}>
                                {/* Icone para editar Perfil */}
                                {profile_data &&
                                    <div
                                        style={{
                                            float: 'left',
                                            position: 'absolute',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginLeft: 138,
                                            marginTop: 110,
                                            minWidth: 33,
                                            minHeight: 33,
                                            backgroundColor: 'white',
                                            borderRadius: '50%',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => set_show_modal(true)}
                                    >
                                        <span className="material-icons" style={{ marginLeft: 1, color: primary_color }}>
                                            edit
                                        </span>
                                    </div>
                                }

                                <div
                                    style={{
                                        margin: 35,
                                        overflow: "hidden",
                                        width: 140,
                                        height: 140,
                                        backgroundColor: '#fff',
                                        borderRadius: '50%',
                                        border: '3px solid lightgray'
                                    }}
                                    className="flex center-h"
                                >
                                    <img
                                        alt=""
                                        style={{ marginLeft: 0, marginTop: 0, width: 140, height: 140, objectFit: "cover" }}
                                        src={glpi_level === 'Cliente' ? `${backend_files_address}/${data_home[0].logomarca.filename}` : (profile_data && profile_data.imagem != '') ? `${backend_files_address}/${profile_data.imagem}` : LogoColor}
                                    />
                                </div>
                            </div>


                            <div style={{ width: "100%", marginTop: 20 }} className="flex flexCol center-h">
                                <div style={{ width: "100%", justifyContent: 'center' }} className="flex">
                                    <div>
                                        {/* NOME DO USUÁRIO LOGADO */}
                                        <span
                                            style={{ fontSize: '1.1em' }}
                                        >
                                            {(profile_data && profile_data.nome != '') ? profile_data.nome : ''}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            {(profile_data && (!glpi_level.match(/Outage-user|Outage-level-1/i))) &&
                                // /* Início Menu */ 
                                < div style={{ marginTop: 30, paddingBottom: 20 }}>
                                    <ul style={{ listStyleType: 'none' }}>
                                        {glpi_level === 'Cliente' &&
                                            <LineMenuHome>
                                                <Link
                                                    style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                                    onClick={() => {
                                                        set_navigation({ page_content: 'clientdetails' })
                                                        set_client_details(data_home[0])
                                                    }}
                                                    to={{
                                                        pathname: "/clientdetails",
                                                        state: { data_client: data_home[0] }
                                                    }}>
                                                    <span className="material-icons">subtitles</span>
                                                    <span>ACESSOS</span>
                                                </Link>
                                            </LineMenuHome>
                                        }

                                        {(glpi_level.match(/Super|Técnico/i)) &&
                                            <>
                                                <LineMenuHome
                                                    onClick={() => {
                                                        set_navigation({ page_content: 'home' })
                                                        if (window.location.pathname !== '/') {
                                                            window.location.href = '/'
                                                        }
                                                    }}
                                                    style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                                >
                                                    <span className="material-icons">forum</span>
                                                    <span>POSTAGENS</span>
                                                </LineMenuHome>


                                                <LineMenuHome
                                                    onClick={() => set_navigation({ page_content: 'formtickets' })}
                                                    style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                                >
                                                    <span className="material-icons">playlist_add</span>
                                                    <span>NOVO CHAMADO</span>
                                                </LineMenuHome>

                                                <LineMenuHome
                                                    onClick={() => {
                                                        set_navigation({ page_content: 'list_clients' })
                                                        if (window.location.pathname !== '/') {
                                                            window.location.href = '/'
                                                        }
                                                    }}
                                                    style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                                >
                                                    <span className="material-icons">grid_view</span>
                                                    <span>CLIENTES</span>
                                                </LineMenuHome>
                                            </>
                                        }

                                        {(glpi_level.match(/Cliente/i)) &&
                                            <LineMenuHome
                                                onClick={() => set_navigation({ page_content: 'formtickets' })}
                                                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                            >
                                                <span className="material-icons">playlist_add</span>
                                                <span>NOVO CHAMADO</span>
                                            </LineMenuHome>
                                        }


                                        <LineMenuHome
                                            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                            onClick={() => {
                                                window.location.href = '/chamados'
                                            }}
                                        >
                                            <span className="material-icons">list</span>
                                            <span>{glpi_level.match(/Super|Técnico/i) ? 'CHAMADOS NOC' : 'CHAMADOS'}</span>
                                        </LineMenuHome>

                                        {(glpi_level.match(/Super|Técnico/i)) &&
                                            <LineMenuHome
                                                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                                onClick={() => {
                                                    set_navigation({ page_content: 'tickets_cgr' })
                                                    if (window.location.pathname !== '/') {
                                                        window.location.href = '/'
                                                    }
                                                }}
                                            >
                                                <div style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
                                                    <span className="material-icons">list</span>
                                                    <span className="material-icons" style={{ fontSize: '1em', marginLeft: -27, marginRight: 15, marginTop: -5 }}>settings</span>
                                                </div>
                                                <span>CHAMADOS CGR</span>
                                            </LineMenuHome>
                                        }

                                        {(glpi_level.match(/Super|Técnico/i)) &&
                                            <LineMenuHome
                                                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                                onClick={() => {
                                                    set_navigation({ page_content: 'tickets_de' })
                                                    if (window.location.pathname !== '/') {
                                                        window.location.href = '/'
                                                    }
                                                }}
                                            >
                                                <div style={{ display: 'flex', flexDirection: 'row', position: 'relative' }}>
                                                    <span className="material-icons">list</span>
                                                    <span className="material-icons" style={{ fontSize: '1em', marginLeft: -27, marginRight: 15, marginTop: -5 }}>code</span>
                                                    <span className="material-icons" style={{ fontSize: '1em', marginLeft: -9, marginRight: 0, marginTop: -5 }}>domain</span>
                                                </div>
                                                <span>CHAMADOS DEV / ENTERPRISE</span>
                                            </LineMenuHome>
                                        }

                                        {(glpi_level.match(/Super|Técnico-Admin/i)) &&
                                            <>
                                                <LineMenuHome
                                                    onClick={() => {
                                                        set_navigation({ page_content: 'list_users' })
                                                        if (window.location.pathname !== '/') {
                                                            window.location.href = '/'
                                                        }
                                                    }}

                                                    style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                                >
                                                    <span className="material-icons">people</span>
                                                    <span>USUÁRIOS</span>
                                                    {/* </Link> */}
                                                </LineMenuHome>
                                            </>
                                        }

                                        {(glpi_level.match(/Super|Técnico/i)) &&
                                            <>
                                                <LineMenuHome
                                                    onClick={() => {
                                                        set_navigation({ page_content: 'suport_calendar' })
                                                        if (window.location.pathname !== '/') {
                                                            window.location.href = '/'
                                                        }
                                                    }}
                                                    style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                                >
                                                    <span className="material-icons">today</span>
                                                    <span>CALENDÁRIO</span>
                                                </LineMenuHome>
                                            </>
                                        }

                                        {(glpi_level.match(/Super|Técnico-Admin/i)) &&
                                            <LineMenuHome
                                                onClick={() => {
                                                    set_navigation({ page_content: 'reports' })
                                                    if (window.location.pathname !== '/') {
                                                        window.location.href = '/'
                                                    }
                                                }}
                                                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                            >
                                                <span className="material-icons">insert_chart</span>
                                                <span>RELATÓRIOS</span>
                                            </LineMenuHome>
                                        }


                                        {(glpi_level.match(/Super|Técnico/i)) &&
                                            <>
                                                <LineMenuHome
                                                    onClick={() => {
                                                        set_navigation({ page_content: 'tools' })
                                                        if (window.location.pathname !== '/') {
                                                            window.location.href = '/'
                                                        }
                                                    }}
                                                    style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                                >
                                                    <span className="material-icons">handyman</span>
                                                    <span>TOOLS</span>
                                                </LineMenuHome>

                                                <LineMenuHome
                                                    onClick={() => {
                                                        set_navigation({ page_content: 'monitoramento' })
                                                        if (window.location.pathname !== '/') {
                                                            window.location.href = '/'
                                                        }
                                                    }}
                                                    style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                                >
                                                    <span className="material-icons">insert_chart</span>
                                                    <span>MONITORAMENTO</span>
                                                </LineMenuHome>

                                                {/* <LineMenuHome
                                                    onClick={() => {
                                                        set_navigation({ page_content: 'mapflowtest' })
                                                        if (window.location.pathname !== '/') {
                                                            window.location.href = '/'
                                                        }
                                                    }}
                                                    style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                                >
                                                    <span className="material-icons">insert_chart</span>
                                                    <span>MAP FLOW TESTE</span>
                                                </LineMenuHome> */}
                                            </>
                                        }

                                        <LineMenuHome
                                            onClick={() => {
                                                set_navigation({ page_content: 'warningscale' })
                                                if (window.location.pathname !== '/') {
                                                    window.location.href = '/'
                                                }
                                            }}
                                            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                        >
                                            <CalendarCheck2 className='ml-[18px]' size={22} />
                                            <span>SOBREAVISO</span>
                                        </LineMenuHome>

                                        {profile_data?.desk_permissions?.show_on_menu === true &&
                                            <LineMenuHome
                                                onClick={() => {
                                                    setViteModal(old => {
                                                        return ({
                                                            show: true,
                                                            component: <DeskAutomation />
                                                        })
                                                    })
                                                }}
                                                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                            >
                                                <Building className='ml-[18px]' size={22} />
                                                <span>ESCRITÓRIO</span>
                                            </LineMenuHome>
                                        }

                                    </ul>

                                    <div
                                        onClick={() => {
                                            if (deferredPrompt !== null) {
                                                deferredPrompt.prompt();
                                                //setupButton.disabled = true;
                                                // Wait for the user to respond to the prompt
                                                deferredPrompt.userChoice.then((choiceResult) => {
                                                    if (choiceResult.outcome === 'accepted') {
                                                        // console.log('PWA setup accepted');
                                                        // hide our user interface that shows our A2HS button
                                                        // setupButton.style.display = 'none';
                                                        set_show_install_button(false)
                                                    } else {
                                                        console.log('PWA setup rejected');
                                                    }
                                                    set_deferredPrompt(null);
                                                });
                                            }
                                        }}
                                        style={{ marginTop: 100, width: 200, position: 'relative', bottom: 15, display: show_install_button ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}
                                    >
                                        <div
                                            style={{
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                border: '1px solid #fff',
                                                padding: 5,
                                                borderRadius: 5
                                            }}
                                        >
                                            <span className="material-icons">get_app</span>
                                            <span style={{ marginRight: 15 }}>Instale o APP</span>
                                        </div>
                                    </div>

                                </div>
                                // FINAL MENU
                            }
                        </div>
                    }
                </>


                {/* {glpi_level.match(/Super|Técnico-Admin/i) && (navigation.page_content === 'home' && window.location.pathname === '/') &&
                    <Link to='/cadclient'>
                        <Fab alt="Adicionar Cliente" className="flex center-h center-v">
                            <span className="material-icons" style={{ fontSize: 33, color: "white", marginLeft: -3 }}>person_add</span>
                        </Fab>
                    </Link>
                } */}
            </div >
        </div >
    );
}

export default LeftSideBar;


