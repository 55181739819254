import React, { useState } from "react";
import { api_bd_nr } from "../../services/Api";
import Modal from "../Modal3";

import { profileData } from "../../atoms/user.atom";
import { useRecoilValue } from "recoil";

import { blinkdropshadow } from './style.module.css'
import { groupArrObjByKey } from "../../util/fns";

export default function CardCustomer(props) {
    const customer = props.customer
    const events = props?.listEvents?.length > 0 ? props?.listEvents?.filter(el => el?.customer?.key === customer?.zabbixkey) : []
    const [showModal, setShowModal] = useState(false)

    const [showNoteModal, setShowNoteModal] = useState(false)
    const [dataNoteModal, setDataNoteModal] = useState({})

    const [showEventNoteModal, setShowEventNoteModal] = useState(false)
    const [dataEventNoteModal, setDataEventNoteModal] = useState({})

    const loggedUser = useRecoilValue(profileData)
    const [noteEvent, setNoteEvent] = useState('')

    // console.log(loggedUser)

    const groups = groupArrObjByKey(events, 'groupKey')
    // console.log('GROUPS', groups)

    return (
        <>
            {/* Modal view event note */}
            <Modal
                show={showEventNoteModal}
                setShow={setShowEventNoteModal}
                zIndex={110}
                // _width="400px"
                // _height="200px"
                onClose={() => {
                    setDataEventNoteModal({})
                }}
                btnCloseStyle={{
                    top: 0,
                    right: 'calc(50dvw - 300px)',// 50dvw less half of children width
                    position: 'absolute',
                    padding: 10,
                }}
            >
                <div
                    className="flex flex-col bg-white rounded p-5"
                    style={{
                        width: 600,
                        height: 365,
                        maxWidth: 600,
                        maxHeight: 365,
                        overflowY: 'auto'
                    }}
                >
                    <p className="text-xl font-bold">Evento:</p>
                    <p className="">Hostname: {dataEventNoteModal?.hostname}</p>
                    <p className="">Data: {dataEventNoteModal?.date ? new Date(dataEventNoteModal?.date).toLocaleDateString() + ' às ' + new Date(dataEventNoteModal?.date).toLocaleTimeString() : ''}</p>
                    <div className="mt-[20px] font-bold">OBS:</div>
                    <div className="">{dataEventNoteModal?.acknowledged?.note}</div>
                    <div className="text-intec-100 mt-2">Analista: {dataEventNoteModal?.acknowledged?.user?.nome}</div>
                </div>
            </Modal>


            {/* Modal form event note */}
            <Modal
                show={showNoteModal}
                setShow={setShowNoteModal}
                zIndex={105}
                // _width="400px"
                // _height="200px"
                // onClose={() => {}}
                btnCloseStyle={{
                    top: 0,
                    right: 'calc(50dvw - 300px)',// 50dvw less half of children width
                    position: 'absolute',
                    padding: 10,
                }}
            >
                <div
                    className="flex flex-col bg-white rounded p-5"
                    style={{
                        width: 600,
                        height: 365,
                        maxWidth: 600,
                        maxHeight: 365
                    }}
                >
                    <p className="text-xl font-bold">Observação sobre o evento (obrigatório)</p>
                    <textarea
                        aria-multiline={true}
                        className="rounded mt-5 w-[100%] h-[250px]"
                        type="text"
                        style={{
                            padding: 5,
                            border: '1px solid #c3c3c3'
                        }}
                        onInput={(e) => {
                            // console.log(e.target.value)
                            setNoteEvent(e.target.value)
                        }}
                    >
                    </textarea>
                    <input
                        className={`mt-3 p-2 rounded text-white ${noteEvent?.length < 5 ? 'bg-gray-300' : 'bg-intec'}`}
                        type="button"
                        disabled={noteEvent?.length < 5}
                        value="Reconhecer Evento"
                        onClick={() => {
                            // console.log(noteEvent, dataNoteModal, loggedUser)
                            api_bd_nr.put(`/outage/customeralerts?opt=ack`, { eventData: dataNoteModal, eventNote: noteEvent, ackUser: loggedUser }).then(({ data }) => {
                                // console.log('URL: ', `/outage/customeralerts`)
                                // console.log(data)
                                if (props?.reloadEvents) {
                                    if (data?.ok === 1) {
                                        alert('Evento reconhecido com sucesso')
                                        setShowNoteModal(false)
                                    }
                                    props.reloadEvents()
                                }
                            }).catch(e => {
                                console.error('PUT EVENT Error: ', e)
                            })
                        }}
                    />
                </div>
            </Modal>




            {/* Modal event list */}
            <Modal
                show={showModal}
                setShow={setShowModal}
                zIndex={90}
                onClose={() => {
                    if (props?.setCustomerAlertData) {
                        props.setCustomerAlertData(current => {
                            return ({ ...current, optionView: 'unack' })
                        })
                    }
                }}
            >
                <div
                    className="w-[90dvw] h-[90dvh] max-w-[90dvw] max-h-[90dvh] bg-white rounded"
                >
                    <p className="p-5 text-xl font-bold">{customer.displayName}</p>
                    <p className="pl-5 font-bold">
                        Visualizar: <select
                            value={props.optionView || 'unack'}
                            onChange={(e) => {
                                if (props?.setCustomerAlertData) {
                                    props.setCustomerAlertData(current => {
                                        return ({ ...current, optionView: e.target.value })
                                    })
                                }
                            }}
                        >
                            <option value="unack">Eventos não reconhecidos</option>
                            <option value="ack">Eventos reconhecidos</option>
                        </select>
                    </p>
                    {props.optionView === 'ack' &&
                        <p className="pl-5 font-bold">
                            Data:  <input
                                type="date"
                                value={props.viewDate}
                                onChange={(e) => {
                                    if (props?.setCustomerAlertData) {
                                        props.setCustomerAlertData(current => {
                                            return ({ ...current, viewDate: e.target.value })
                                        })
                                    }
                                }}
                            />
                        </p>
                    }
                    <div className="flex flex-row flex-wrap gap-5 p-4 mt-4 w-[100%] max-w-[100%] max-h-[calc(90dvh_-_80px)] overflow-y-auto">
                        {Object.keys(groups).map(el => (
                            <div
                                key={`g_${el}`}
                                className="flex flex-col justify-center p-3 rounded min-w-[300px] w-[300px] h-[150px] relative"
                                style={{
                                    border: "solid 1px rgb(224, 224, 224)",
                                    boxShadow: "#0006 2px 3px 10px",
                                }}
                            >
                                {groups?.[el]?.length > 0 &&
                                    <div className="flex flex-row justify-center items-center font-bold absolute right-[-11px] top-[-10px] bg-intec rounded-full min-w-[30px] min-h-[30px] text-white">
                                        <span className="m-0">{String(groups[el].length).padStart(2, '0')}</span>
                                    </div>
                                }
                                <p><span className="m-0 font-bold">HostName: </span>{groups[el][0].hostname}</p>
                                <p><span className="m-0 font-bold">Severity: </span>{groups[el][0].severity}</p>
                                <p><span className="m-0 font-bold">Tipo: </span>{groups[el][0].type}</p>
                                <p><span className="m-0 font-bold">Data: </span>{groups[el][0].date ? new Date(groups[el][0].date).toLocaleDateString() + ' às ' + new Date(groups[el][0].date).toLocaleTimeString() : ''}</p>
                                {groups[el][0]?.acknowledged?.status ? (
                                    <div
                                        className="w-full mt-3 rounded bg-green-500 text-white p-2 cursor-pointer"
                                        onClick={() => {
                                            setDataEventNoteModal(groups[el][0])
                                            setShowEventNoteModal(true)
                                        }}
                                    >
                                        ACK: {groups[el][0].acknowledged.user.nome}
                                    </div>
                                ) : (
                                    <input
                                        className="w-full mt-3 rounded bg-intec text-white p-2 cursor-pointer"
                                        type="button"
                                        value="Reconhecer evento"
                                        onClick={() => {
                                            setShowNoteModal(true)
                                            setDataNoteModal(groups[el][0])
                                        }}
                                    />
                                )}

                            </div>
                        ))}

                        {events.length === 0 && props?.optionView === 'unack' &&
                            <div
                                className="w-full mt-3 rounded bg-green-500 text-white p-2"
                            >
                                TUDO OK!
                            </div>
                        }

                        {events.length === 0 && props?.optionView === 'ack' &&
                            <div
                                className="w-full mt-3 rounded bg-intec text-white p-2"
                            >
                                Nenhum evento reconhecido para a pesquisa atual
                            </div>
                        }
                    </div>
                </div>
            </Modal>


            {/* <div className={`flex flex-row items-start m-4 ${events?.length > 0 ? 'bg-red-500' : 'bg-intec' } text-white rounded py-3 px-4 min-w-[400px] space-x-4 items-center`}> */}
            <div className={`flex flex-row m-4 border-intec ${events?.length > 0 ? blinkdropshadow : ''} bg-intec text-white rounded py-3 px-4 min-w-[400px] space-x-4 items-center relative`}>
                <div>
                    <img
                        className="w-[100px] rounded-full bg-white"
                        src={`https://apisgi.intecsolutions.com.br/api/customers/getimg/${customer.username}`}
                    />
                </div>
                <div className="w-full flex flex-col items-center relative">
                    <div className="flex flex-row">
                        <p className="text-[1.5rem]">
                            <span className="text-[1.5rem] m-0 font-bold">{customer.displayName}</span>
                            {/* {customer.displayName} */}
                        </p>
                    </div>
                    <input
                        className="w-full mt-3 rounded bg-intec-700 text-white p-2 cursor-pointer"
                        type="button"
                        value="Listar eventos"
                        onClick={() => {
                            // console.log(!showModal)
                            setShowModal(!showModal)
                        }}
                    />
                </div>
                {events.length > 0 &&
                    <div
                        className="flex justify-center items-center w-fit h-fit p-2 bg-red-600"
                        style={{
                            position: 'absolute',
                            // marginLeft: 10,
                            right: -5,
                            // marginTop: -8,
                            top: -5,
                            minWidth: 30,
                            borderRadius: '50%',
                            aspectRatio: '1/1'
                        }}
                    ><span className="m-0 font-bold text-[1.1rem]">{events.length}</span></div>
                }
            </div>
        </>
    )
}
