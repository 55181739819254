import React, { useState, useEffect, memo, useMemo } from 'react';

import './Outages.css';

import TextField from '@material-ui/core/TextField';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import OutageDetail from './OutageDetail';
import FormRompimento from './FormRompimento';
import FormEstacaoIndisponivel from './FormEstacaoIndisponivel';
import FormLinkComProblema from './FormLinkComProblema';
import FormClienteComProblema from './FormClienteComProblema';

import { api_bd } from '../../services/Api'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import customerData from '../../atoms/customers.atom'
import outagesComponentToShowState, { outagesListTicketState } from '../../atoms/outages_state.atom';

import Modal from '../Modal';
import ModalRFO from '../ModalRFO';
import NewOutage from './NewOutage';
import NewInteraction from './NewInteraction';
import OutageList from './OutageList';
import CloseTicket from './CloseTicket';
import Rfo from './Rfo.js';
import EditOutage from './EditOutage';
import OutageReport from './OutageReport';
import CustomerAlerts from '../CustomerAlerts/index.js';

import OutageTeamNotes from '../OutageTeamNotes';

import { msToTime } from '../../util/fns';
import LoadCustomerAlerts from '../CustomerAlerts/LoadCustomerAlerts.js';
import customerAlertsState, { alertsCounterState } from '../../atoms/customerAlerts.js';


const { backend_files_address } = require('../../config.json')

const ShowOption = (props) => {
	const FloatAddButtom = ({ showForm }) => {
		return (
			<div
				className='default_color'
				style={{
					width: 50,
					height: 50,
					display: 'flex',
					borderRadius: 25,
					justifyContent: 'center',
					alignItems: 'center',
					position: 'absolute',
					bottom: 20,
					right: 30,
					cursor: 'pointer',
					border: '1px solid #fff5',
					zIndex: 30
				}}
				onClick={(e) => {
					showForm(true)
				}}
			>
				<span className="material-icons left_icon default_color" style={{ margin: 0, color: '#fff', fontSize: '2.3em' }}>add</span>
			</div>
		)
	}

	switch (props.option) {
		case 'dashboard':
			return (<>
				<OutageList />
				<FloatAddButtom showForm={props.showForm} />
			</>)
			break;
		case 'relatorios':
			return (<OutageReport />)
			break;
		case 'customerAlerts':
			return (<CustomerAlerts />)
			break;

		default:
			return (
				<>
					<OutageList />
					<FloatAddButtom showForm={props.showForm} />
				</>
			)
			break;
	}
}

function Outages(props) {
	// const [eventList, setEventList] = useState([])
	const [showFormNewEvent, setShowFormNewEvent] = useState(false)

	const [componentToShow, setComponentToShow] = useRecoilState(outagesComponentToShowState)

	const setEventList = useSetRecoilState(outagesListTicketState)

	const profile_user = useRecoilValue(profileData)

	const [viewOption, setViewoption] = useState('dashboard')

	// const FloatAddButtom = ({ showForm }) => {
	// 	return (
	// 		<div
	// 			className='default_color'
	// 			style={{
	// 				width: 50,
	// 				height: 50,
	// 				display: 'flex',
	// 				borderRadius: 25,
	// 				justifyContent: 'center',
	// 				alignItems: 'center',
	// 				position: 'absolute',
	// 				bottom: 20,
	// 				right: 30,
	// 				cursor: 'pointer',
	// 				border: '1px solid #fff5'
	// 			}}
	// 			onClick={(e) => {
	// 				showForm(true)
	// 			}}
	// 		>
	// 			<span className="material-icons left_icon default_color" style={{ margin: 0, color: '#fff', fontSize: '2.3em' }}>add</span>
	// 		</div>
	// 	)
	// }

	const buscarEventos = async () => {
		let tmpEv = await api_bd.get(`/outages/status/aberto`)
		// console.log(tmpEv.data)
		if (tmpEv.data.length === 0) {
			// setLoadMessage('Nenhum evento encontrado para os filtros selecionados')
		} else {
			if (componentToShow === null) {
				setEventList(tmpEv.data)
			}
		}
	}

	const ContentComponent = () => {
		switch (componentToShow.component) {
			case 'newInteraction':
				return (
					<NewInteraction ev={componentToShow.data} callbackfn={async () => {
						setComponentToShow(null)
					}} />
				)
				break;
			case 'outageDetail':
				return (
					<OutageDetail ev={componentToShow.data} callbackfn={async () => {
						setComponentToShow(null)
					}} />
				)
				break;

			case 'closeTicket':
				return (
					<CloseTicket ev={componentToShow.data} callbackfn={async () => {
						setComponentToShow(null)
					}} />
				)
				break;

			case 'rfo':
				return (
					<Rfo ev={componentToShow.data} callbackfn={async () => {
						setComponentToShow(null)
					}} />
				)
				break;

			case 'edit-outage':
				return (
					<EditOutage ev={componentToShow.data} buscarEventos={buscarEventos} callbackfn={async () => {
						setComponentToShow(null)
					}} />
				)
				break;

			default:
				break;
		}
	}

	useEffect(() => {
		buscarEventos()
	}, [componentToShow])

	useEffect(() => {
		buscarEventos()
	}, [showFormNewEvent])

	return (
		// <div className='container'>
		<div className='w-[calc(100%_-_25px)] max-h-[calc(100dvh_-_50px)]'>
			<div className='card' style={{ width: '100%', flexDirection: 'row', padding: 10, alignItems: 'flex-start' }}>
				<div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
					<span style={{ fontSize: '1.8em', fontWeight: 'bold' }}>OUTAGES</span>
					<div style={{ width: '100%', marginTop: 20, flexDirection: 'row', alignItems: 'flex-start', position: 'relative' }}>
						<span
							style={
								viewOption === 'dashboard'
									?
									{ fontSize: '1.4em', fontWeight: 'bold', textDecoration: 'underline', textDecorationThickness: '0.2em', cursor: 'pointer' }
									:
									{ fontSize: '1.4em', fontWeight: 'bold', cursor: 'pointer' }
							}
							onClick={() => { setViewoption('dashboard') }}
						>
							DASHBOARD
						</span>
						<span
							style={
								viewOption === 'relatorios'
									?
									{ fontSize: '1.4em', fontWeight: 'bold', textDecoration: 'underline', textDecorationThickness: '0.2em', cursor: 'pointer' }
									:
									{ fontSize: '1.4em', fontWeight: 'bold', cursor: 'pointer' }
							}
							onClick={() => { setViewoption('relatorios') }}
						>
							RELATÓRIOS
						</span>
						{/* <span
							style={
								viewOption === 'customerAlerts'
									?
									{ fontSize: '1.4em', fontWeight: 'bold', textDecoration: 'underline', textDecorationThickness: '0.2em', cursor: 'pointer' }
									:
									{ fontSize: '1.4em', fontWeight: 'bold', cursor: 'pointer' }
							}
							onClick={() => { setViewoption('customerAlerts') }}
						>
							ALERTAS CLIENTES 
							{customerAlertsCounter?.counter > 0 &&
								// <span className='mt-[-5px] ml-[5px] text-center text-sm absolute p-2 w-[26px] h-[26px] bg-intec text-white rounded-full'>{String(customerAlertsCounter?.counter||0).padStart(2, '0')}</span>
								<>&nbsp;({String(customerAlertsCounter?.counter||0).padStart(2, '0')})</>
							}
						</span> */}

						<LoadCustomerAlerts showLabel={true} viewOption={viewOption} setViewoption={setViewoption} />

						{/* {customerAlertsCounter?.counter > 0 &&
								// <span className='mt-[-5px] ml-[5px] text-center text-sm absolute p-2 w-[26px] h-[26px] bg-intec text-white rounded-full'>{String(customerAlertsCounter?.counter||0).padStart(2, '0')}</span>
								<span className='ml-2 text-xl'>({String(customerAlertsCounter?.counter||0).padStart(2, '0')})</span>
							} */}

					</div>
				</div>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center', width: '50%', height: '100%' }}>
					<OutageTeamNotes />
				</div>
			</div>

			{/* Content */}

			<ShowOption option={viewOption} showForm={setShowFormNewEvent} />

			{/* {viewOption === 'dashboard' ?
				(
					<>
						<OutageList />
						<FloatAddButtom showForm={setShowFormNewEvent} />
					</>
				)
				:
				(
					<OutageReport />
				)
			} */}



			{showFormNewEvent &&
				<Modal callbackfn={() => {
					setShowFormNewEvent(false)
				}}>
					<div className='card' style={{ padding: 30, width: '80%', maxWidth: '80%', height: '85vh', backgroundColor: '#fff', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
						<NewOutage callbackfn={() => {
							setShowFormNewEvent(false)
						}} />
					</div>
				</Modal>
			}


			{/* {(evDetail !== null) &&
					<Modal callbackfn={() => {
						setEvDetail(null)
					}}>
						<OutageDetail ev={evDetail} />
					</Modal>
				} */}


			{componentToShow !== null &&
				<Modal callbackfn={() => {
					setComponentToShow(null)
				}}>
					<div className='card' style={{ padding: 30, width: '80%', maxWidth: '80%', height: '85vh', backgroundColor: '#fff', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
						<ContentComponent />
					</div>
				</Modal>
			}
		</div>
	);
}

export default Outages;