import React, { useState, useEffect } from 'react';
import { XCircle } from 'lucide-react'

const Modal = (props) => {
    const [device_height, set_device_height] = useState(null)
    const [device_width, set_device_width] = useState(null)
    const [showModal, setShowModal] = useState(props.show || false)

    const handleClose = () => {
        setShowModal(false);
        if (props?.setShow) {
            props.setShow(false)
        }
    };

    const getWidth = () => {
        //Access the node here and get the width
        // return Object.keys(props?.children.props) //or clientWidth();
        // return props?.children?.offsetWidth(); //or clientWidth();
        return props?.children?.props?.style?.width; //or clientWidth();
    }

    useEffect(() => {
        setShowModal(props.show)
    }, [props.show])

    useEffect(() => {
        if (showModal === false) {
            if (props?.onClose) {
                props.onClose()
            }
        }
    }, [showModal])

    useEffect(() => {
        document.onkeydown = function (evt) {
            evt = evt || window.event;
            var isEscape = false;
            if ("key" in evt) {
                isEscape = (evt.key === "Escape" || evt.key === "Esc");
            } else {
                isEscape = (evt.keyCode === 27);
            }

            if (isEscape) {
                handleClose()
            }
        }

        if(props.children){
            // console.log('children width:', getWidth())
        }

    }, [])

    return (
        showModal ? (
            <div
                style={{
                    backgroundColor: '#0006',
                    minHeight: '100vh',
                    minWidth: '100vw',
                    position: 'absolute',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    left: 0,
                    top: 0,
                    zIndex: props.zIndex ? props.zIndex : 100
                }}
            >
                <div
                    style={
                        // props?.styleContent || 
                        {
                            display: 'flex',
                            flexDirection: 'row',
                            position: 'relative',
                            width: props?._width ? props?._width : '100%',
                            height: props?._height ? props?._height : '100%',
                            maxWidth: props?._width ? props?._width : '100%',
                            maxHeight: props?._height ? props?._height : '100%',
                            justifyContent: 'center',//...props._style,
                            alignItems: 'center',//...props._style,
                        }
                    }
                >
                    <div
                        style={{
                            // right: 60,
                            top: 0,
                            right: '5%',
                            position: 'absolute',
                            padding: 10,
                            // width: props?.children && props?.children?.props?.style?.width,
                            ...props.btnCloseStyle
                        }}
                    >
                        <XCircle
                            size={30}
                            onClick={handleClose}
                            style={{
                                color: 'inherit',
                                cursor: 'pointer'
                            }}
                        />
                    </div>
                    {props.children}
                </div>

            </div>
        ) : (
            <></>
        )
    )
}

export default Modal;