import React, { useState, useEffect } from 'react';
import './Navbar.css';
import {
	// Redirect,
	useHistory,
	// useLocation
} from "react-router-dom";
// import * as FaIcons from 'react-icons/fa';
// import * as AiIcons from 'react-icons/ai';
// import * as IoIcons from 'react-icons/io';
import { Link } from 'react-router-dom';
// import { SidebarData } from '../SidebarData';
import { IconContext } from 'react-icons';

import { api_bd } from '../../services/Api'

import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import navigationState from '../../atoms/navigate_state.atom'
import clientDetails from '../../atoms/client_details.atom'

import Logo from '../../assets/logo-intec.svg'

import InputAutoComplete from '../InputAutoComplete'

import Toast from '../Toast';

import { useParams } from "react-router-dom";
import { showMenuState } from '../../atoms/appState';

// import TicketRunning from '../TicketRunning';


const config = require('../../config.json')


function Navbar(props) {

	const { url_parameter } = useParams();


	const [sidebar, setSidebar] = useState(false);
	// const [view_location, set_view_location] = useState('')

	const [user_data,] = useState({})

	const sgi_user = useRecoilValue(userState)
	const profile_data = useRecoilValue(profileData)
	const [navigation_state, set_navigation_state] = useRecoilState(navigationState)

	const set_client_details = useSetRecoilState(clientDetails)

	// const [device_width, set_device_width] = useState(null)
	const [show_client_seacrh, set_show_client_seacrh] = useState(false)

	const [list_clients, set_list_clients] = useState([])
	const [glpi_level, set_glpi_level] = useState(null)

	// const showSidebar = () => setSidebar(true);
	const hideSideBar = () => setSidebar(false)

	const [show_toast, set_show_toast] = useState(false)
	const [error_message, set_error_message] = useState(null)

	const [showMenu, setShowMenu] = useRecoilState(showMenuState)

	let soundPlaying = false

	let history = useHistory();

	// const niveis = {
	//   cliente: "Cliente",// (tem acesso acesso apenas as informações, sem ediçao)
	//   tecnico: "Técnico", // (tem acesso acesso as informações e cadastros das informações de acesso)
	//   admin: "Admin"// (tem acesso as informações, cadastro de acessos e cadastros de clientes)
	// }

	// Register SW, Register Push, Send Push
	async function send(_idu) {
		// Register Service Worker
		// console.log("Registering service worker...");
		const register = await navigator.serviceWorker.register("/worker.js", {
			scope: "/"
		});


		// Register Push
		// console.log("Registering Push...");

		const subscription = await register.pushManager.subscribe({
			userVisibleOnly: true,
			applicationServerKey: urlBase64ToUint8Array(config.publicVapidKey)
		});

		// Send Push Notification
		// console.log("Sending Push...");
		const push_subscribe = await api_bd.post('push_notifications/subscribe', { id_user: _idu, subscription })

		//console.log(push_subscribe.data);

	}


	function urlBase64ToUint8Array(base64String) {
		const padding = "=".repeat((4 - base64String.length % 4) % 4)
		const base64 = (base64String + padding)
			.replace(/\-/g, "+")
			.replace(/_/g, "/")

		const rawData = window.atob(base64)
		const outputArray = new Uint8Array(rawData.length);

		for (let i = 0; i < rawData.length; ++i) {
			outputArray[i] = rawData.charCodeAt(i)
		}
		return outputArray;
	}


	const subscribe_push_notifications = (_idu, callback) => {
		// Check for service worker
		if ("serviceWorker" in navigator) {
			send(_idu).catch(err => console.error(err))
		}
	}


	useEffect(() => {
		(async () => {

			if (window.location.pathname === '/' && (Object.keys(navigation_state).length === 0)) {
				set_navigation_state({ page_content: 'home' })
			}

			if (window.location.pathname.match(/(\/chamados\/)([\d]+)|(\/chamados)/)) {
				const args_url = window.location.pathname.match(/(\/chamados\/)([\d]+)|(\/chamados)/)

				if (args_url[2]) {
					const searched_ticket = await api_bd.get(`tickets/codid/${parseInt(args_url[2])}`)

					if (searched_ticket.data.length > 0 && ((searched_ticket.data[0].customer_id === profile_data.id) || (profile_data.nivel.match(/Super|Técnico/)))) {
						set_navigation_state({ page_content: 'ticket_detail', data: searched_ticket.data[0] })
					} else {
						set_navigation_state({ page_content: 'listtickets' })
						set_error_message(<span style={{ fontSize: '1.3em' }}>Não foi encontrado nenhum chamado com o ID: <b style={{ fontSize: '1em' }}>{args_url[2]}</b></span>)
						set_show_toast(true)
						setTimeout(() => {
							set_show_toast(false)
							set_error_message(null)
						}, 5000)
					}
				} else {
					set_navigation_state({ page_content: 'listtickets' })
				}

			}


			if (profile_data.id) {
				subscribe_push_notifications(profile_data.id, () => {
					// console.log('ServiceWorker Carregado.')
				});
			}



		})()

		try {
			const broadcast = new BroadcastChannel('service-ch');
			broadcast.onmessage = (event) => {
				if (event.data && event.data.type === 'playsound') {
					// if(soundPlaying === false){
					// soundPlaying = true
					// console.log("play sound")
					//process messag
					const audio = new Audio(event.data.url);
					audio.play()
					// audio.onended(()=>{
					// 	soundPlaying = false
					// })
					// }
				}
			};
		} catch (error) {
			console.log(error)
		}

	}, [])

	useEffect(() => {
		// console.log('NAVBAR', profile_data)
		set_glpi_level(profile_data.nivel)


		let intervalVerifyUser = setInterval(() => {
			api_bd.get(`/users/getstate/${profile_data.id}`).then(resp => {
				if (resp.data.ativo === false) {
					window.location.href = '/logout'
				}
			})
		}, 60000)

		return () => {
			clearInterval(intervalVerifyUser)
		}

	}, [profile_data])

	useEffect(() => {
		if (show_client_seacrh) {
			let listIsNotFocused = setInterval(() => {
				if (document.getElementById('listclients')) {
					clearInterval(listIsNotFocused)
					document.getElementById('listclients').focus()
				}
			}, 300)
		}
	}, [show_client_seacrh])

	useEffect(() => {
		(async () => {
			const dados = await api_bd.get(`/customers`)
			const ordered_data = dados.data ? await dados.data.sort((a, b) => a.nome.localeCompare(b.nome)) : []
			set_list_clients(ordered_data)
		})()
	}, [glpi_level])

	return (
		<>
			{show_toast &&
				<Toast _style={{ backgroundColor: '#3c5ca6cc', color: 'white' }}>
					{error_message}
				</Toast>
			}
			<IconContext.Provider value={{ color: '#fff' }}>
				<div className='navbar' onBlur={hideSideBar} style={{ width: "100%" }}>
					{/* <div className=" ticketsTableMin">
						<span style={{ fontSize: '2.3em' }} className="material-icons">menu</span>
					</div> */}
					
					<div style={{ width: "100%", display: "flex" }} >
						<div className='flex flex-row items-center w-[150px] relative'>
							<div
								style={{
									// top: 12,
									paddingTop: 8,
									marginLeft: 15,
									// left: 10,
									// position: 'absolute',
									// zIndex: 1
								}}
								onClick={() => {
									setShowMenu(!showMenu)
								}}
							>
								<span style={{ cursor: 'pointer', color: '#fff', fontSize: '2.3em', margin: 0 }} className="material-icons">menu</span>
							</div>
							<Link
								// style={{ marginLeft: 40 }}
								// className="container_logo_header"
								onClick={() => set_navigation_state({ page_content: 'home' })} to="/">
								<img src={Logo}
									style={{
										marginTop: -3,
										width: 45,
										minWidth: 45
										// backgroundColor: 'red'
									}} />
							</Link>
						</div>
						<div
							className='noprint'
							style={{
								display: "flex",
								width: "100%",
								alignItems: "center",
								justifyContent: "flex-end",
								marginRight: 30,
								color: "white"
							}}>

							{/* <TicketRunning /> */}

							<Link onClick={() => set_navigation_state({ page_content: 'home' })} to="/">
								<span>HOME</span>
							</Link>
							{profile_data.nivel.match(/Super|Técnico/i) &&
								<span
									style={{ marginLeft: 35, cursor: 'pointer' }}
									onClick={() => {
										set_show_client_seacrh(!show_client_seacrh)
									}}
								>CLIENTES</span>
							}

							{list_clients && list_clients.length > 0 && show_client_seacrh && (profile_data.nivel.match(/Super|Técnico/i)) &&
								<div style={{
									position: 'absolute',
									borderBottomLeftRadius: 10,
									borderBottomRightRadius: 10,
									width: 300,
									height: 130,
									top: 0,
									marginTop: 50,
									backgroundColor: '#3c5ca6',
									zIndex: 1
								}}
									onBlur={() => {
										// console.log('blurrr')
										set_show_client_seacrh(false)
									}}
								>
									<div style={{ paddingTop: 20, paddingLeft: 20, color: 'white' }}>Pesquisar Cliente: </div>
									<InputAutoComplete
										OptionLabel="nome"
										returnData={async (data) => {
											if (data !== undefined && data !== '') {
												const cli_data = list_clients.filter(cli => (cli.nome === data))
												set_show_client_seacrh(false)
												set_client_details(cli_data[0])
												// history.push("/nav", { data: cli_data[0], path: '/clientdetails'})
												history.push("/clientdetails")
											}
										}}
										data={list_clients}
										_style={{
											paddingTop: 10,
											paddingLeft: 20,
											paddingRight: 20,
											paddingBottom: 20,
											color: '#fff'
										}}
									/>
								</div>
							}
							{/* <a target="_blank" href="http://intecsolutions.com.br/suporte"><span>SUPORTE</span></a> */}
						</div>
					</div>
					{/* <Link to='#' className='menu-bars justify-content-end'><FaIcons.FaBars onClick={showSidebar} /></Link> */}
					<Link to="/logout" className='noprint'>
						<span className="material-icons" style={{ color: "white" }}>logout</span>
					</Link>
				</div>

				{/* TESTAR PARA REMOVER */}
				{/* <nav className={sidebar ? 'nav-menu active' : 'nav-menu'} style={{ marginTop: 40 }}>
					<ul className='nav-menu-items' onClick={hideSideBar}>
						{Object.keys(user_data).length > 0
							&& SidebarData.map((item, index) => {
								if (item.nivel === user_data.glpiactiveprofile.name || item.nivel.match(/Cliente/)) {
									return (
										<li key={index} className={item.cName}>
											<Link to={item.path}>
												{item.icon}
												<span>{item.title}</span>
											</Link>
										</li>
									)
								}
							})}
					</ul>
				</nav> */}


			</IconContext.Provider>
		</>
	);
}

export default Navbar;