import { atom, selector  } from 'recoil'
// import { recoilPersist } from 'recoil-persist'

const customerAlertsState = atom({
    key: 'customerAlertsState', // unique ID (with respect to other atoms/selectors)
    default: {
        counter: 0, 
        events: [], 
        customerList: [], 
        optionView: 'unack',
        viewDate: new Date().toLocaleDateString('en-ca')
    }, // default value (aka initial value)
    // effects_UNSTABLE: [persistAtom],
});

export const alertsCounterState = selector({
    key: 'alertsCounterState', // unique ID (with respect to other atoms/selectors)
    get: ({ get }) => {
        const data = get(customerAlertsState);
        const ret = data?.events?.length || 0
        return ret
    },
});

export const alertsCustomerListState = selector({
    key: 'alertsCustomerListState', // unique ID (with respect to other atoms/selectors)
    get: ({ get }) => {
        const data = get(customerAlertsState);
        const ret = data.customerList 
        return ret
    },
});

export const alertsViewOptionState = selector({
    key: 'alertsViewOptionState', // unique ID (with respect to other atoms/selectors)
    get: ({ get }) => {
        const data = get(customerAlertsState);
        const ret = data.optionView
        return ret
    },
});

export const alertsViewDateState = selector({
    key: 'alertsViewDateState', // unique ID (with respect to other atoms/selectors)
    get: ({ get }) => {
        const data = get(customerAlertsState);
        const ret = data.viewDate
        return ret
    },
});

// const customerAlertsListState = atom({
//     key: 'customerAlertsListState', // unique ID (with respect to other atoms/selectors)
//     default: [], 
//     // default value (aka initial value)
//     // effects_UNSTABLE: [persistAtom],
// });

export default customerAlertsState