import React from "react";
import LoadCustomerAlerts from "./LoadCustomerAlerts";
import CustomerAlerts from ".";

export default function CustomerAlertsDashboard(props) {
    return (
        <div className="pr-[25px]">
            <LoadCustomerAlerts />
            <CustomerAlerts standalone={true}/>
        </div>
    )

}